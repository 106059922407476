@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

* {
  padding: 0;
  margin: 0;
}

.listnav {
  padding: 10px 12px;
}

.listnav svg {
  margin-right: 5px;
  margin-left: 6px;
}

thead tr th {
  text-align: center;
}

.cardSign {
  text-align: center;
  /* background-color: white; */
}

.cardSign img {
  width: 100px;
  margin: auto;
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

a {
  text-decoration: none !important;
}

.dark .dark\:shadow-none {
  background-color: #ef3f40 !important;
}

.dark .bg-lightPrimary {
  background-color: #191919 !important;
}

.text-brand-500 {
  color: #ef3f40 !important;
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

form input,
form select,
form textarea {
  width: 95% !important;
  margin-top: 20px !important;
  border-radius: 20px;
  display: block;
  padding: 10px;
  margin: auto;
  border: 1px solid rgba(66, 42, 251, 0.236);
  background-color: white;
}

.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}

.toggle {
  appearance: none;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 50px;
  position: relative;
  outline: none;
  transition: background-color 0.2s;
}

.toggle:checked {
  background-color: #ef3f40;
  /* Change color when toggled */
}

.toggle:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: transform 0.2s;
}

.toggle:checked:before {
  transform: translateX(20px);
  /* Move the toggle handle */
}

.flexToggle {
  justify-content: space-between;
  width: 100%;
}

.page-link {
  color: #ef3f40 !important;
  border: none !important;
}

.active > .page-link,
.page-link.active {
  background-color: #ef3f40 !important;
  color: #fff !important;
  padding: 10px;
}

.pagination {
  display: flex;
  justify-content: space-around;
}

.visually-hidden {
  display: none;
}

header h5 {
  font-weight: 700;
}

input:focus {
  outline: none;
  border: 1px solid #ef3f40 !important;
}

button {
  background-color: #ef3f40;
  color: white;
  padding: 10px;
  border-radius: 12px !important;
}

.spanStatus {
  padding: 2px 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  width: 110px;
  justify-content: center;
  margin: auto;
}

.spanStatus.Processing {
  background-color: #fcdcbd;
  color: #eb7500;
}

.spanStatus.Progress,
.spanStatus.progress {
  background-color: #e6e6f2;
  color: #5b528e;
}

.spanStatus.Optimizing {
  background-color: #c9e3fc;
  color: #324558;
}

.spanStatus.Completed {
  background-color: #ccffc8;
  color: #127909;
}

.spanStatus.Refunded {
  background-color: #e0e0e0;
  color: #6c757d;
}

.spanStatus.Queue {
  background-color: #e6e6fa;
  color: #4b0082;
}

.spanStatus.Cancelled {
  background: #ffd6d6;
  color: red;
}
